import Link from "next/link";
import { serviceItems2 } from "../../data/mainMenuData";
import { isActiveParent, isActiveLink } from "../../utils/linkActiveChecker";
import { useRouter } from "next/router";
import useTranslation from 'next-translate/useTranslation';

const HeaderNavContent = () => {
    const router = useRouter();
    const { t, lang } = useTranslation('common');

    return (
        <>
            <nav className="nav main-menu">
                <ul className="navigation" id="navbar">
                    {/* current dropdown */}
                    <li className={`${isActiveLink(t('menu.0.routePath'), router.asPath) ? "current" : ""}`}>
                        <Link href={`/${lang}${t('menu.0.routePath')}`}>
                            {t('menu.0.title')}
                        </Link>
                    </li>
                    {/* End homepage menu items */}

                    <li
                        className={`${
                            isActiveParent(serviceItems2, router.asPath)
                                ? "current"
                                : ""
                        } dropdown`}
                    >
                        <span>{t('menu.1.title')}</span>
                        <div className="mega-menu">
                            <div className="mega-menu-bar row">
                                {serviceItems2.map((item) => (
                                    <div
                                        className="column"
                                        key={item.id}
                                    >
                                        <h3>
                                            <Link href={`/${lang}${t('menu.1.mainRoute')}`}>
                                                {t('menu.1.mainTitle')}
                                            </Link>
                                        </h3>
                                        <ul>
                                            {item.items.map((menu, i) => (
                                                <li
                                                    className={
                                                        isActiveLink(
                                                            t(`menu.1.items.${i}.route`),
                                                            router.asPath
                                                        )
                                                            ? "current"
                                                            : ""
                                                    }
                                                    key={i}
                                                >
                                                    <Link href={`/${lang}${t(`menu.1.items.${i}.route`)}`}>
                                                        {t(`menu.1.items.${i}.name`)}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </li>

                    {/* <li
                        className={`${
                            isActiveParentChaild(caseStudyItems, router.asPath)
                                ? "current"
                                : ""
                        } dropdown`}
                    >
                        <span>Studia przypadków</span>
                        <ul>
                            {caseStudyItems.map((item, i) => (
                                <li
                                    className={
                                        isActiveLink(
                                            item.routePath,
                                            router.asPath
                                        )
                                            ? "current"
                                            : ""
                                    }
                                    key={i}
                                >
                                    <Link href={item.routePath}>
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </li> */}

                    {/* <li className={`${isActiveLink("/case-studies", router.asPath) ? "current" : ""}`}>
                        <Link href='/case-studies'>
                            Studia przypadków
                        </Link>
                    </li> */}

                    <li className={`${isActiveLink(t(`menu.2.routePath`), router.asPath) ? "current" : ""}`}>
                        <Link href={`/${lang}${t('menu.2.routePath')}`}>
                            {t(`menu.2.title`)}
                        </Link>
                    </li>
                    <li className={`${isActiveLink(t(`menu.3.routePath`), router.asPath) ? "current" : ""}`}>
                        <Link href={`/${lang}${t('menu.3.routePath')}`}>
                            {t(`menu.3.title`)}
                        </Link>
                    </li>
                    <li className={`${isActiveLink(t(`menu.4.routePath`), router.asPath) ? "current" : ""}`}>
                        <Link href={`/${lang}${t('menu.4.routePath')}`}>
                            {t(`menu.4.title`)}
                        </Link>
                    </li>
                </ul>
            </nav>
        </>
    );
};

export default HeaderNavContent;
