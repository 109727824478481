"use client";

import dynamic from "next/dynamic";
import Link from "next/link";
import {
    ProSidebarProvider,
    Sidebar,
    Menu,
    MenuItem,
    SubMenu,
} from "react-pro-sidebar";

import mobileMenuData from "../../../data/mobileMenuData";
import SidebarFooter from "./SidebarFooter";
import SidebarHeader from "./SidebarHeader";
import {
    isActiveLink,
    isActiveParentChaild,
} from "../../../utils/linkActiveChecker";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

const Index = () => {
    const router = useRouter();
    const { t, lang } = useTranslation('common');

    return (
        <div
            className="offcanvas offcanvas-start mobile_menu-contnet"
            tabIndex="-1"
            id="offcanvasMenu"
            data-bs-scroll="true"
        >
            <SidebarHeader />

            <ProSidebarProvider>
                <Sidebar>
                    <Menu>
                        {mobileMenuData.map((item, key) => (
                            item?.items ? (
                                <SubMenu
                                    className={
                                        isActiveParentChaild(
                                            item.items,
                                            router.asPath
                                        )
                                            ? "menu-active"
                                            : ""
                                    }
                                    label={t(`sidebarMobile.${key}.title`)}
                                    key={`${item.key}_${key}`}
                                >
                                    {item.items.map((menuItem, i) => (
                                        <MenuItem
                                            className={
                                                isActiveLink(
                                                    menuItem.routePath,
                                                    router.asPath
                                                )
                                                    ? "menu-active-link"
                                                    : ""
                                            }
                                            key={`${menuItem.routePath}_${i}`}
                                            routerLink={
                                                <Link href={`/${lang}${t(`sidebarMobile.${key}.items.${i}.route`)}`} />
                                            }
                                        >
                                            {t(`sidebarMobile.${key}.items.${i}.name`)}
                                        </MenuItem>
                                    ))}
                                </SubMenu>
                            ) : (
                                <MenuItem
                                    key={key}
                                    className={
                                        isActiveLink(
                                            item.routePath,
                                            router.asPath
                                        )
                                            ? "menu-active-link"
                                            : ""
                                    }
                                    routerLink={
                                        <Link href={`/${lang}${t(`sidebarMobile.${key}.routePath`)}`} />
                                    }
                                >
                                    {t(`sidebarMobile.${key}.title`)}
                                </MenuItem>
                            )))
                        }
                    </Menu>
                </Sidebar>
            </ProSidebarProvider>
            <SidebarFooter />
        </div>
    );
};

export default dynamic(() => Promise.resolve(Index), { ssr: false });
