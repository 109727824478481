module.exports = [
  {
    id: 1,
    title: "Współpraca",
    menuList: [
      {
          name: 'Wyszukiwanie kandydatów',
          route: "/services/recruitment"
      },
      {
          name: 'Budowanie zespołów',
          route: "/services/team-building"
      },
      {
          name: 'Leasing pracowników',
          route: "/services/leasing"
      }
    ],
  },
  {
    id: 2,
    title: "Poznaj nas",
    menuList: [
      // { name: "Studia przypadków", route: "/case-studies" },
      { name: "FAQ", route: "/faq" },
    ],
  },
  {
    id: 4,
    title: "Przydatne linki",
    menuList: [
      { name: "Strona główna", route: "/" },
      { name: "Kontakt", route: "/contact" },
      { name: "Regulamin", route: "/terms" },
      { name: "Polityka prywatności", route: "/privacy-policy" },
    ],
  },
];
