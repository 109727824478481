import Link from "next/link";
import footerContent from "../../../data/footerContent";
import useTranslation from "next-translate/useTranslation";

const FooterContent = () => {
  const { t, lang } = useTranslation("footer");
  return (
    <>
      {footerContent.map((item, index) => (
        <div
          className="footer-column col-lg-3 col-md-6 col-sm-12"
          key={item.id}
        >
          <div className="footer-widget links-widget">
            <h4 className="widget-title">{t(`columns.${index}.title`)}</h4>
            <div className="widget-content">
              <ul className="list">
                {item?.menuList?.map((_, i) => (
                  <li key={i}>
                    <Link href={`/${lang}${t(`columns.${index}.menu.${i}.route`)}`}>{t(`columns.${index}.menu.${i}.name`)}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default FooterContent;
