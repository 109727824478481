import useTranslation from "next-translate/useTranslation";
import setLanguage from 'next-translate/setLanguage';

const LanguageSwitch = () => {
  const { lang } = useTranslation("common")

  return (
    <div className="language-box">
      <div
        className={`language-option ${lang == 'pl' ? 'language-option-active' : ''}`}
        role="button"
        onClick={() => setLanguage('pl')}
      >
        PL
      </div>
      <div className="language-divider">|</div>
      <div
        className={`language-option ${lang == 'en' ? 'language-option-active' : ''}`}
        role="button"
        onClick={() => setLanguage('en')}
      >
        ENG
      </div>
    </div>
  )
};

export default LanguageSwitch;
