import { serviceItems } from "./mainMenuData";

const mobileMenuData = [
    {
        id: 1,
        label: "Headhunti",
        routePath: "/",
    },
    {
        id: 2,
        label: "Usługi",
        items: serviceItems,
    },
    {
        id: 3,
        label: "Ofert pracy",
        routePath: "/jobs",
    },
    {
        id: 4,
        label: "Blog",
        routePath: "/blog",
    },
    {
        id: 5,
        label: "Kontakt",
        routePath: "/contact",
    },
];

export default mobileMenuData;
