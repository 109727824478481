import Link from "next/link";
import MobileSidebar from "./mobile-sidebar";
import useTranslation from "next-translate/useTranslation";
import LanguageSwitch from "../common/LanguageSwitch";

const MobileMenu = () => {
    const { lang } = useTranslation("common");
    return (
        // <!-- Main Header-->
        <header className="main-header main-header-mobile">
            <div className="auto-container">

                <div className="main-preheader">
                    <div className="outer-box">
                    <LanguageSwitch />
                    </div>
                    <div className="outer-box">
                    <div className="phone">
                        <a href="tel:+48537575739">
                        +48&nbsp;537&nbsp;575&nbsp;739
                        </a>
                    </div>
                    <div className="email">
                        <a href="mailto:kontakt@headhunti.pl">kontakt@headhunti.pl</a>
                    </div>
                    </div>
                </div>
                {/* <!-- Main box --> */}
                <div className="inner-box">
                    <div className="nav-outer">
                        <div className="logo-box">
                            <div className="logo">
                                <Link href={`/${lang}`}>
                                    <img src="/images/logo.svg" alt="brand" />
                                </Link>
                            </div>
                        </div>
                        {/* End .logo-box */}

                        <MobileSidebar />
                        {/* <!-- Main Menu End--> */}
                    </div>
                    {/* End .nav-outer */}

                    <div className="outer-box">
                        {/* <LanguageSwitch /> */}
                        <a
                            href="#"
                            className="mobile-nav-toggler"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasMenu"
                        >
                            <span className="flaticon-menu-1"></span>
                        </a>
                        {/* right humberger menu */}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default MobileMenu;
