const Social = () => {
  const socialContent = [
    { id: 1, icon: "fa-facebook-f", link: "https://www.facebook.com/headhunti" },
    // { id: 2, icon: "fa-threads", link: "https://www.threads.net/@headhunti_" },
    { id: 3, icon: "fa-instagram", link: "https://www.instagram.com/headhunti_" },
    { id: 4, icon: "fa-linkedin-in", link: "https://www.linkedin.com/company/headhunti" },
  ];
  return (
    <>
      {socialContent.map((item) => (
        <a
          href={item.link}
          target="_blank"
          rel="noopener noreferrer"
          key={item.id}
        >
          <i className={`fab ${item.icon}`}></i>
        </a>
      ))}
    </>
  );
};

export default Social;
