import useTranslation from "next-translate/useTranslation";
import LanguageSwitch from "../../common/LanguageSwitch";

const SidebarFooter = () => {
  const socialContent = [
    { id: 1, icon: "fa-facebook-f", link: "https://www.facebook.com/headhunti" },
    // { id: 2, icon: "fa-threads", link: "https://www.threads.net/@headhunti_" },
    { id: 3, icon: "fa-instagram", link: "https://www.instagram.com/headhunti_" },
    { id: 4, icon: "fa-linkedin-in", link: "https://www.linkedin.com/company/headhunti" },
  ];
  const { t } = useTranslation("common")

  return (
    <div className="mm-add-listing mm-listitem pro-footer">
      <button
        type="button"
        className="theme-btn btn-style-one mm-listitem__text call-modal"
        data-bs-toggle="modal"
        data-bs-target="#inquiryPopupModal"
      >
        {t('button.offer')}
      </button>
      {/* job post btn */}

      <div className="mm-listitem__text">
        {/* <LanguageSwitch />
        <div className="contact-info">
          <p className="phone-num">
            <span>{t('button.contact')}</span>
            <a href="tel:+48537575739">+48 537 575 739</a>
          </p>
          <a href="mailto:kontakt@headhunti.pl" className="email">
            kontakt@headhunti.pl
          </a>
          <span className="address">
            Grzybowska 60
            <br />00-844 Warszawa <br />
          </span>
        </div> */}
        {/* End .contact-info */}

        {/* <div className="social-links">
          {socialContent.map((item) => (
            <a
              href={item.link}
              target="_blank"
              rel="noopener noreferrer"
              key={item.id}
            >
              <i className={`fab ${item.icon}`}></i>
            </a>
          ))}
        </div> */}
        {/* End social-links */}
      </div>
      {/* End .mm-listitem__text */}
    </div>
  );
};

export default SidebarFooter;
